// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { wordCloudConstants } from '@config';
import * as Highcharts from 'highcharts';
import wordcloud from 'highcharts/modules/wordcloud';
wordcloud(Highcharts);

interface CloudData {
  name: string;
  weight: number;
}

@Component({
  selector: 'lfx-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.scss'],
})
export class WordCloudComponent implements AfterViewInit, OnDestroy {
  @Input() wordCloudData: any[] = [];
  @Input() color: string = '#002447';
  @Output() wordCloudClickEventEmitter = new EventEmitter();
  @ViewChild('wordChartContainer') wordChartContainerRef!: ElementRef;
  wordCloudConstants = wordCloudConstants;
  wordCloudChart: any;
  data: CloudData[] = [];

  constructor() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initWordCloud();
    });
  }

  public ngOnDestroy(): void {
    if (this.wordCloudChart) {
      this.wordCloudChart.destroy();
    }
  }

  private initWordCloud(): void {
    if (!this.wordCloudData || !this.wordCloudData.length) {
      return;
    }

    this.wordCloudChart = Highcharts.chart(
      this.wordChartContainerRef.nativeElement,
      this.wordCloudChartOptions()
    );
  }

  private wordCloudChartOptions(): Highcharts.Options {
    const that = this;

    const logScaleData = this.linearToLogarithmic(
      this.wordCloudData.map(item => item.count)
    );
    const scaledData = this.scaleValues(logScaleData, 10, 4);

    this.wordCloudData.map((ele, index) => {
      this.data.push({
        name: ele.word,
        weight: scaledData[index],
      });
    });

    return {
      colors: ['#002447'], // same color to all words
      credits: {
        enabled: false, // to hide highchart.com watermark on bottom right corner
      },

      plotOptions: {
        series: {
          cursor: 'pointer',
          events: {
            click: function (e: any) {
              const word =
                e &&
                e.srcElement &&
                e.srcElement.point &&
                e.srcElement.point.name;

              that.wordCloudClickEventEmitter.emit(word);
            },
          },
        },
      },
      series: [
        {
          type: 'wordcloud',
          data: this.data,
          placementStrategy: 'center',
          rotation: {
            from: 10,
            to: 0,
          },
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        enabled: false,
      },
    };
  }

  /**
   * Scale values in the array from min_range to max_range.
   * @param arr the input array of numbers
   * @param max_range the maximum range value
   * @param min_range the minimum range values
   */
  private scaleValues(
    arr: number[],
    max_range: number,
    min_range: number
  ): number[] {
    const mx = Math.max(...arr);
    const mi = Math.min(...arr);

    return arr.map(item =>
      Math.round(
        ((max_range - min_range) * (item - mi)) / (mx - mi) + min_range
      )
    );
  }

  /**
   * Converts the linear data to logarithmic data.
   * @param arr the input array of data values to convert
   */
  private linearToLogarithmic(arr: number[]): number[] {
    return arr.map(item => Math.log10(item));
  }
}
