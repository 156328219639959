// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { dashboardConstants } from '@config';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'lfx-project-search-autocomplete',
  templateUrl: './project-search-autocomplete.component.html',
  styleUrls: ['./project-search-autocomplete.component.scss'],
})
export class ProjectSearchAutocompleteComponent implements OnChanges {
  @Input() data: any[] = [];
  @Input() isSearchLoading: boolean = false;
  @Input() placeholder: string = '';
  @Input() searchText: string = '';
  @Input() searchIcon: boolean = false;
  @Input() width: string = '600';
  @Input() minQueryLength: number = 2;
  @Input() searchKeyword: string = 'projectName';
  @Input() notFoundText: string = dashboardConstants.noResultsFound;
  @Output() searchTextEmitter = new EventEmitter<string>();
  @Output() resetSearchEmitter = new EventEmitter();
  @Output() selectedProjectEmitter = new EventEmitter();
  @ViewChild('autoSearch') autoSearch!: any;

  faSearchIcon = faSearch;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.searchKeyword =
      this.searchKeyword === 'project' ? 'projectName' : this.searchKeyword;
  }

  public onSelectAutocompleteProject(item: any): void {
    this.selectedProjectEmitter.emit(item);
  }

  public onChangeSearch(searchText: string): void {
    if (searchText.length > 0) {
      this.searchText = searchText;
      this.searchTextEmitter.emit(cloneDeep(searchText && searchText.trim()));
    } else {
      this.autoSearch.close();
    }
  }

  public onClearSearch(): void {
    this.searchText = '';
    this.autoSearch.close();
    this.resetSearchEmitter.emit();
  }
}
