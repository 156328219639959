// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const pageNotFoundConstants = {
  errorCode404: '404',
  title: 'Sorry, the page not found',
  subtitle:
    'The link you followed is probably broken, or page has been removed.',
  returnHomePage: 'Return to Home Page',
};
