// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const projectScoresConstants = {
  cvssNist: 'cvss (common vulnerability scoring system) - NIST',
  cvssNistInfo:
    'This is the average of CVSS scores for all repos in this project.',
  secretsAndCompliance: 'secrets and compliance risk score',
  secretsAndComplianceInfo:
    'This is the average of Normalized Risk Scores for all repos in this project.',
  ciiBestPractices: 'ossf best practices score',
  ciiBestPracticesInfo:
    'Tiered percentage level are as follows: less than 100% In progress, between 100% to 200% passing, between 200% to 300% silver and for more than 300% it is gold.',
  noScoreAvailable: 'No score available.',
  clickHere: 'click here',
  noScoreFound: 'No OSSF Best Practices Score found.',
  setupOSSFBestPractices: 'to setup OSSF Best Practices score for your project',
  please: 'Please',
  noOSSFScoreFound: 'No OSSF Best Practices Score found.',
  Please: 'Please',
  toSetupOSSFScore: 'to setup OSSF Best Practices score for your project',
};
