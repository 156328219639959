// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pageNotFoundConstants } from '@config';

@Component({
  selector: 'lfx-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  pageNotFoundConstants = pageNotFoundConstants;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  public redirectToDashboard() {
    this.router.navigate([``]);
  }
}
