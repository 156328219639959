// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
interface Color {
  color: string;
}

export const languageColorCodes: { [key: string]: Color } = {
  '1c enterprise': { color: '#814ccc' },
  '4d': { color: '' },
  abap: { color: '#e8274b' },
  actionscript: { color: '#882b0f' },
  ada: { color: '#02f88c' },
  agda: { color: '#315665' },
  'ags script': { color: '#b9d9ff' },
  'al code': { color: '#3aa2b5' },
  alloy: { color: '#64c800' },
  'alpine abuild': { color: '' },
  ampl: { color: '#e6efbb' },
  angelscript: { color: '#c7d7dc' },
  antlr: { color: '#9dc3ff' },
  apex: { color: '#1797c0' },
  'api blueprint': { color: '#2acca8' },
  apl: { color: '#5a8164' },
  'apollo guidance computer': { color: '' },
  applescript: { color: '#101f1f' },
  arc: { color: '#aa2afe' },
  asl: { color: '' },
  'asp.net': { color: '#9400ff' },
  aspectj: { color: '#a957b0' },
  assembly: { color: '#6e4c13' },
  asymptote: { color: '#ff0000' },
  ats: { color: '#1ac620' },
  augeas: { color: '' },
  autohotkey: { color: '#6594b9' },
  autoit: { color: '#1c3552' },
  awk: { color: '' },
  ballerina: { color: '#ff5000' },
  batchfile: { color: '#c1f12e' },
  befunge: { color: '' },
  bison: { color: '' },
  bitbake: { color: '' },
  blade: { color: '#f7523f' },
  blitzbasic: { color: '' },
  blitzmax: { color: '#cd6400' },
  bluespec: { color: '' },
  boo: { color: '#d4bec1' },
  brainfuck: { color: '#2f2530' },
  brightscript: { color: '' },
  c: { color: '#555555' },
  'c#': { color: '#178600' },
  'c++': { color: '#f34b7d' },
  'c2hs haskell': { color: '' },
  "cap'n proto": { color: '' },
  cartocss: { color: '' },
  ceylon: { color: '#dfa535' },
  chapel: { color: '#8dc63f' },
  charity: { color: '' },
  chuck: { color: '' },
  cirru: { color: '#ccccff' },
  clarion: { color: '#db901e' },
  'classic asp': { color: '#6a40fd' },
  clean: { color: '#3f85af' },
  click: { color: '#e4e6f3' },
  clips: { color: '' },
  clojure: { color: '#db5855' },
  cmake: { color: '' },
  cobol: { color: '' },
  codeql: { color: '' },
  coffeescript: { color: '#244776' },
  coldfusion: { color: '#ed2cd6' },
  'coldfusion cfc': { color: '' },
  'common lisp': { color: '#3fb68b' },
  'common workflow language': { color: '#b5314c' },
  'component pascal': { color: '#b0ce4e' },
  cool: { color: '' },
  coq: { color: '' },
  crystal: { color: '#000100' },
  cson: { color: '#244776' },
  csound: { color: '' },
  'csound document': { color: '' },
  'csound score': { color: '' },
  css: { color: '#563d7c' },
  cuda: { color: '#3a4e3a' },
  cweb: { color: '' },
  cycript: { color: '' },
  cython: { color: '' },
  d: { color: '#ba595e' },
  dafny: { color: '#ffec25' },
  dart: { color: '#00b4ab' },
  dataweave: { color: '#003a52' },
  dhall: { color: '#dfafff' },
  'digital command language': { color: '' },
  dm: { color: '#447265' },
  dockerfile: { color: '#384d54' },
  dogescript: { color: '#cca760' },
  dtrace: { color: '' },
  dylan: { color: '#6c616e' },
  e: { color: '#ccce35' },
  ec: { color: '#913960' },
  ecl: { color: '#8a1267' },
  eclipse: { color: '' },
  eiffel: { color: '#4d6977' },
  elixir: { color: '#6e4a7e' },
  elm: { color: '#60b5cc' },
  'emacs lisp': { color: '#c065db' },
  emberscript: { color: '#fff4f3' },
  eq: { color: '#a78649' },
  erlang: { color: '#b83998' },
  'f#': { color: '#b845fc' },
  'f*': { color: '#572e30' },
  factor: { color: '#636746' },
  fancy: { color: '#7b9db4' },
  fantom: { color: '#14253c' },
  faust: { color: '#c37240' },
  'filebench wml': { color: '' },
  filterscript: { color: '' },
  fish: { color: '' },
  flux: { color: '#88ccff' },
  forth: { color: '#341708' },
  fortran: { color: '#4d41b1' },
  'fortran free form': { color: '' },
  freemarker: { color: '#0050b2' },
  frege: { color: '#00cafe' },
  futhark: { color: '#5f021f' },
  'g-code': { color: '#d08cf2' },
  'game maker language': { color: '#71b417' },
  gaml: { color: '#ffc766' },
  gams: { color: '' },
  gap: { color: '' },
  'gcc machine description': { color: '' },
  gdb: { color: '' },
  gdscript: { color: '#355570' },
  genie: { color: '#fb855d' },
  genshi: { color: '' },
  'gentoo ebuild': { color: '' },
  'gentoo eclass': { color: '' },
  gherkin: { color: '#5b2063' },
  glsl: { color: '' },
  glyph: { color: '#c1ac7f' },
  gnuplot: { color: '#f0a9f0' },
  go: { color: '#00add8' },
  golo: { color: '#88562a' },
  gosu: { color: '#82937f' },
  grace: { color: '' },
  'grammatical framework': { color: '#ff0000' },
  groovy: { color: '#e69f56' },
  'groovy server pages': { color: '' },
  hack: { color: '#878787' },
  haml: { color: '#ece2a9' },
  handlebars: { color: '#f7931e' },
  harbour: { color: '#0e60e3' },
  haskell: { color: '#5e5086' },
  haxe: { color: '#df7900' },
  hcl: { color: '' },
  hiveql: { color: '#dce200' },
  hlsl: { color: '' },
  holyc: { color: '#ffefaf' },
  html: { color: '#e34c26' },
  hy: { color: '#7790b2' },
  hyphy: { color: '' },
  idl: { color: '#a3522f' },
  idris: { color: '#b30000' },
  'igor pro': { color: '#0000cc' },
  'inform 7': { color: '' },
  'inno setup': { color: '' },
  io: { color: '#a9188d' },
  ioke: { color: '#078193' },
  isabelle: { color: '#fefe00' },
  'isabelle root': { color: '' },
  j: { color: '#9eedff' },
  jasmin: { color: '' },
  java: { color: '#b07219' },
  'java server pages': { color: '' },
  javascript: { color: '#f1e05a' },
  'javascript+erb': { color: '' },
  jflex: { color: '' },
  jison: { color: '' },
  'jison lex': { color: '' },
  jolie: { color: '#843179' },
  jsoniq: { color: '#40d47e' },
  jsonnet: { color: '#0064bd' },
  jsx: { color: '' },
  julia: { color: '#a270ba' },
  'jupyter notebook': { color: '#da5b0b' },
  'kaitai struct': { color: '#773b37' },
  kotlin: { color: '#f18e33' },
  krl: { color: '#28430a' },
  labview: { color: '' },
  lasso: { color: '#999999' },
  latte: { color: '#f2a542' },
  lean: { color: '' },
  less: { color: '#1d365d' },
  lex: { color: '#dbca00' },
  lfe: { color: '#4c3023' },
  lilypond: { color: '' },
  limbo: { color: '' },
  'literate agda': { color: '' },
  'literate coffeescript': { color: '' },
  'literate haskell': { color: '' },
  livescript: { color: '#499886' },
  llvm: { color: '#185619' },
  logos: { color: '' },
  logtalk: { color: '' },
  lolcode: { color: '#cc9900' },
  lookml: { color: '#652b81' },
  loomscript: { color: '' },
  lsl: { color: '#3d9970' },
  lua: { color: '#000080' },
  m: { color: '' },
  m4: { color: '' },
  m4sugar: { color: '' },
  macaulay2: { color: '#d8ffff' },
  makefile: { color: '#427819' },
  mako: { color: '' },
  markdown: { color: '#083fa1' },
  marko: { color: '#42bff2' },
  mask: { color: '#f97732' },
  mathematica: { color: '' },
  matlab: { color: '#e16737' },
  max: { color: '#c4a79c' },
  maxscript: { color: '#00a6a6' },
  mcfunction: { color: '#e22837' },
  mercury: { color: '#ff2b2b' },
  meson: { color: '#007800' },
  metal: { color: '#8f14e9' },
  minid: { color: '' },
  mirah: { color: '#c7a938' },
  'mirc script': { color: '#3d57c3' },
  mlir: { color: '#5ec8db' },
  modelica: { color: '' },
  'modula-2': { color: '' },
  'modula-3': { color: '#223388' },
  'module management system': { color: '' },
  monkey: { color: '' },
  moocode: { color: '' },
  moonscript: { color: '' },
  'motorola 68k assembly': { color: '' },
  mql4: { color: '#62a8d6' },
  mql5: { color: '#4a76b8' },
  mtml: { color: '#b7e1f4' },
  muf: { color: '' },
  mupad: { color: '' },
  myghty: { color: '' },
  nasl: { color: '' },
  ncl: { color: '#28431f' },
  nearley: { color: '#990000' },
  nemerle: { color: '#3d3c6e' },
  nesc: { color: '#94b0c7' },
  netlinx: { color: '#0aa0ff' },
  'netlinx+erb': { color: '#747faa' },
  netlogo: { color: '#ff6375' },
  newlisp: { color: '#87aed7' },
  nextflow: { color: '#3ac486' },
  nim: { color: '#ffc200' },
  nit: { color: '#009917' },
  nix: { color: '#7e7eff' },
  nsis: { color: '' },
  nu: { color: '#c9df40' },
  numpy: { color: '' },
  'objective-c': { color: '#438eff' },
  'objective-c++': { color: '#6866fb' },
  'objective-j': { color: '#ff0c5a' },
  objectscript: { color: '#424893' },
  ocaml: { color: '#3be133' },
  odin: { color: '#60affe' },
  omgrofl: { color: '#cabbff' },
  ooc: { color: '#b0b77e' },
  opa: { color: '' },
  opal: { color: '#f7ede0' },
  'open policy agent': { color: '' },
  opencl: { color: '' },
  'openedge abl': { color: '' },
  openqasm: { color: '#aa70ff' },
  'openrc runscript': { color: '' },
  openscad: { color: '' },
  ox: { color: '' },
  oxygene: { color: '#cdd0e3' },
  oz: { color: '#fab738' },
  p4: { color: '#7055b5' },
  pan: { color: '#cc0000' },
  papyrus: { color: '#6600cc' },
  parrot: { color: '#f3ca0a' },
  'parrot assembly': { color: '' },
  'parrot internal representation': { color: '' },
  pascal: { color: '#e3f171' },
  pawn: { color: '#dbb284' },
  pep8: { color: '#c76f5b' },
  perl: { color: '#0298c3' },
  php: { color: '#4f5d95' },
  picolisp: { color: '' },
  piglatin: { color: '#fcd7de' },
  pike: { color: '#005390' },
  plpgsql: { color: '' },
  plsql: { color: '#dad8d8' },
  pogoscript: { color: '#d80074' },
  pony: { color: '' },
  postscript: { color: '#da291c' },
  'pov-ray sdl': { color: '' },
  powerbuilder: { color: '#8f0f8d' },
  powershell: { color: '#012456' },
  prisma: { color: '#0c344b' },
  processing: { color: '#0096d8' },
  prolog: { color: '#74283c' },
  'propeller spin': { color: '#7fa2a7' },
  pug: { color: '#a86454' },
  puppet: { color: '#302b6d' },
  purebasic: { color: '#5a6986' },
  purescript: { color: '#1d222d' },
  python: { color: '#3572a5' },
  'python console': { color: '' },
  q: { color: '#0040cd' },
  'q#': { color: '#fed659' },
  qmake: { color: '' },
  qml: { color: '#44a51c' },
  'qt script': { color: '#00b841' },
  quake: { color: '#882233' },
  r: { color: '#198ce7' },
  racket: { color: '#3c5caa' },
  ragel: { color: '#9d5200' },
  raku: { color: '#0000fb' },
  raml: { color: '#77d9fb' },
  rascal: { color: '#fffaa0' },
  realbasic: { color: '' },
  reason: { color: '#ff5847' },
  rebol: { color: '#358a5b' },
  red: { color: '#f50000' },
  redcode: { color: '' },
  "ren'py": { color: '#ff7f7f' },
  renderscript: { color: '' },
  rexx: { color: '' },
  ring: { color: '#2d54cb' },
  riot: { color: '#a71e49' },
  robotframework: { color: '' },
  roff: { color: '#ecdebe' },
  rouge: { color: '#cc0088' },
  rpc: { color: '' },
  ruby: { color: '#701516' },
  runoff: { color: '#665a4e' },
  rust: { color: '#dea584' },
  sage: { color: '' },
  saltstack: { color: '#646464' },
  sas: { color: '#b34936' },
  sass: { color: '#a53b70' },
  scala: { color: '#c22d40' },
  scheme: { color: '#1e4aec' },
  scilab: { color: '' },
  scss: { color: '#c6538c' },
  sed: { color: '#64b970' },
  self: { color: '#0579aa' },
  shaderlab: { color: '' },
  shell: { color: '#89e051' },
  shellsession: { color: '' },
  shen: { color: '#120f14' },
  sieve: { color: '' },
  slash: { color: '#007eff' },
  slice: { color: '#003fa2' },
  slim: { color: '#2b2b2b' },
  smali: { color: '' },
  smalltalk: { color: '#596706' },
  smarty: { color: '' },
  smpl: { color: '#c94949' },
  smt: { color: '' },
  solidity: { color: '#aa6746' },
  sourcepawn: { color: '#f69e1d' },
  sqf: { color: '#3f3f3f' },
  sqlpl: { color: '' },
  squirrel: { color: '#800000' },
  'srecode template': { color: '#348a34' },
  stan: { color: '#b2011d' },
  'standard ml': { color: '#dc566d' },
  starlark: { color: '#76d275' },
  stata: { color: '' },
  stylus: { color: '#ff6347' },
  supercollider: { color: '#46390b' },
  svelte: { color: '#ff3e00' },
  svg: { color: '#ff9900' },
  swift: { color: '#ffac45' },
  swig: { color: '' },
  systemverilog: { color: '#dae1c2' },
  tcl: { color: '#e4cc98' },
  tcsh: { color: '' },
  terra: { color: '#00004c' },
  tex: { color: '#3d6117' },
  thrift: { color: '' },
  'ti program': { color: '#a0aa87' },
  tla: { color: '' },
  tsql: { color: '' },
  tsx: { color: '' },
  turing: { color: '#cf142b' },
  twig: { color: '#c1d026' },
  txl: { color: '' },
  typescript: { color: '#2b7489' },
  'unified parallel c': { color: '' },
  'unix assembly': { color: '' },
  uno: { color: '#9933cc' },
  unrealscript: { color: '#a54c4d' },
  urweb: { color: '' },
  v: { color: '#4f87c4' },
  vala: { color: '#fbe5cd' },
  vba: { color: '#867db1' },
  vbscript: { color: '#15dcdc' },
  vcl: { color: '#148aa8' },
  verilog: { color: '#b2b7f8' },
  vhdl: { color: '#adb2cb' },
  'vim script': { color: '#199f4b' },
  'visual basic .net': { color: '#945db7' },
  volt: { color: '#1f1f1f' },
  vue: { color: '#2c3e50' },
  wdl: { color: '#42f1f4' },
  webassembly: { color: '#04133b' },
  webidl: { color: '' },
  wisp: { color: '#7582d1' },
  wollok: { color: '#a23738' },
  x10: { color: '#4b6bef' },
  xbase: { color: '#403a40' },
  xc: { color: '#99da07' },
  xojo: { color: '' },
  xproc: { color: '' },
  xquery: { color: '#5232e7' },
  xs: { color: '' },
  xslt: { color: '#eb8ceb' },
  xtend: { color: '' },
  yacc: { color: '#4b6c4b' },
  yaml: { color: '#cb171e' },
  yara: { color: '#220000' },
  yasnippet: { color: '#32ab90' },
  zap: { color: '#0d665e' },
  zeek: { color: '' },
  zenscript: { color: '#00bcd1' },
  zephir: { color: '#118f9e' },
  zig: { color: '#ec915c' },
  zil: { color: '#dc75e5' },
  zimpl: { color: '' },
};
