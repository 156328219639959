// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const licensesConstants = {
  licenses: 'Licenses',
  license: 'License',
  selectLicenses: 'Select Licenses',
  selectPackageUnder: 'Select Package Under',
  repositoriesUnder: 'Repositories Under',
  downloadCSV: 'Download CSV',
  package: 'Package',
  packages: 'Packages',
  repositoryManifestFile: 'Repository-Manifest File',
  NoLicensesDetected: 'No licenses detected',
  LicenseNotFound: 'License not found.',
  NoManifestFileFound: 'No manifest file found',
  SearchLicenses: 'Search Licenses',
};
