// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { DecimalPipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { doughnutChartConstants } from '@config';
import { CodeSecretsTypesInput } from '@models';
import {
  SeperateNumberByCommaPipe,
  ReplaceCharactersPipe,
} from '@lfx/shared/pipes';
import * as Highcharts from 'highcharts';
import { color } from 'highcharts';

@Component({
  selector: 'lfx-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() doughnutChartData!: CodeSecretsTypesInput[];
  @Input() codeSecretIssuesCount: number = 0;
  @Input() showLegend: boolean = false;
  @Input() isPieChart: boolean = false;
  @Input() view: string = 'code-secrets';
  @Input() chartTitle: string = doughnutChartConstants.codeSecretIssues;
  @Input() chartError: string = doughnutChartConstants.errorMessage;
  @Input() height: string = '250';
  @Input() width: string = '400';
  @Output() selectedTypeEmitter = new EventEmitter();
  @ViewChild('doughnutChartContainer') doughnutChartContainerRef!: ElementRef;
  doughnutChartConstants = doughnutChartConstants;
  doughnutChart: any;

  constructor(
    private seperateNumberByCommaPipe: SeperateNumberByCommaPipe,
    private replaceCharactersPipe: ReplaceCharactersPipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    setTimeout(() => this.initDoughnutChart());

    if (!this.doughnutChartData && this.doughnutChart) {
      this.doughnutChart.destroy();
    }
  }

  ngAfterViewInit(): void {
    this.initDoughnutChart();
  }

  public ngOnDestroy(): void {
    if (this.doughnutChart) {
      this.doughnutChart.destroy();
    }
  }

  private initDoughnutChart(): void {
    if (this.doughnutChartContainerRef) {
      this.doughnutChart = Highcharts.chart(
        this.doughnutChartContainerRef.nativeElement,
        this.doughnutChartOptions()
      );
    }
  }

  private doughnutChartOptions(): any {
    const that = this;

    return {
      chart: {
        backgroundColor: 'transparent',
        type: 'pie',
        events: {
          load: function () {
            if (!that.isPieChart) {
              // that.codeSecretIssuesCount
              // to set dough nut chart centered title
              const chartEle: any = this;

              chartEle.setTitle({
                text: `<div class="title-box">
                <div class="title-value">${that.seperateNumberByCommaPipe.transform(
                  that.codeSecretIssuesCount
                )}</div>
                <div class="title">${that.chartTitle}</div>
                </div>`,
                useHTML: true,
              });
            }
          },
        },
      },
      credits: {
        enabled: false, // to hide highchart.com watermark on bottom right corner
      },
      plotOptions: {
        pie: {
          innerSize: !this.isPieChart ? '75%' : '0%', // arc width
          dataLabels: {
            enabled: false,
          },
          showInLegend: this.isPieChart,
          states: {
            hover: {
              brightness: 0,
              halo: {
                size: 2, // hover border width
              },
            },
            inactive: {
              enabled: true,
              opacity: 0.6, // opacity for inactive
            },
          },
          events: {
            click: function (e: any) {
              if (that.view === 'code-secrets') {
                const typeName = that.replaceCharactersPipe.transform(
                  e.point.name,
                  ' ',
                  '_'
                );

                that.selectedTypeEmitter.emit({ alertType: typeName });
              }
            },
          },
        },
      },
      legend: {
        align: 'center',
        verticalAlign: 'top',
        x: 0,
        y: 10,
        itemMarginBottom: 10,
        itemStyle: {
          fontFamily: 'Source Sans Pro',
          color: '#333333',
          fontSize: '12px',
          fontWeight: 'thin',
          align: 'center',
        },
      },

      title: {
        text: !this.isPieChart ? this.chartTitle : '',
        verticalAlign: 'middle',
        y: 0,
        x: 0,
      },
      tooltip: {
        className: 'doughnut-chart-tooltip',
        headerFormat: '',
        footerFormat: '',
        backgroundColor: '#fff',
        style: {
          display: 'flex',
          flexDirection: 'row',
        },
        outside: true,
        pointFormatter: function () {
          const tooltipHoverEle: any = this;

          if (that.view === 'code-secrets') {
            return that.codeSecretTypesTooltip(tooltipHoverEle);
          } else if (that.view === 'overview-vulnerability') {
            return that.overviewVulnDetectedDoughNutChartTooltip(
              tooltipHoverEle
            );
          } else {
            return tooltipHoverEle.percentage;
          }
        },
        shared: true,
        shadow: false,
        useHTML: true,
        valueDecimals: 2,
      },
      series: [
        {
          data: this.doughnutChartData,
        },
      ],
    };
  }

  private overviewVulnDetectedDoughNutChartTooltip(tooltipHoverEle: any) {
    // displayed vuln value
    let tooltip = '<div class="tooltip-box">';

    tooltipHoverEle.series.data.forEach((d: any) => {
      tooltip += `<div class="tooltip-box-item"><span class="color-circle" style="background: ${
        d.color
      }"></span>
      <span class="text mr-2.5">${
        d.options && this.decimalPipe.transform(d.options.y, '2.')
      }</span></div>`;
    });
    tooltip += '</div>';

    return tooltip;
  }

  private codeSecretTypesTooltip(tooltipHoverEle: any) {
    // displayed secret type percentage
    return `<div class="tooltip-box">
                <span class="color-circle" style="background: ${
                  tooltipHoverEle.color
                }"></span>
                <span class="text">${
                  tooltipHoverEle.name
                } (${tooltipHoverEle.y.toFixed(2)}%)</span>
             </div>`;
  }
}
