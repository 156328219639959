// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const vulnerabilityDetailViewConstants = {
  overview: 'Overview',
  disclosed: 'Disclosed',
  published: 'Published',
  repositoryManifestFile: 'Repository Manifest File',
  snykId: 'Snyk ID',
  credit: 'Credit',
  vulnerableModule: 'Vulnerable module',
  introducedThrough: 'Introduced through',
  exploitMaturity: 'Exploit maturity',
  fixedIn: 'Fixed in',
  learnMore: 'Learn More',
  detailsPathsAndRemediation: 'Details Paths and Remediation',
  Remediation: 'Remediation',
  vulnerableFunction: 'Vulnerable Functions',
  reference: 'Reference',
  githubCommit: 'Github Commit',
  createGithubIssue: 'Create a Github Issue',
};
