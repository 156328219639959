// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCaretDown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { dashboardConstants } from '@config';
import { DropDown } from '@models';

@Component({
  selector: 'lfx-project-filter',
  templateUrl: './project-filter.component.html',
  styleUrls: ['./project-filter.component.scss'],
})
export class ProjectFilterComponent implements OnInit {
  @Output() selectItemEmitter = new EventEmitter();
  selectedItem: string = dashboardConstants.project;
  data: DropDown[] = [
    {
      label: dashboardConstants.project,
      formattedLabel: dashboardConstants.projectTooltip,
      value: 'project',
    },
    {
      label: dashboardConstants.cve,
      formattedLabel: dashboardConstants.cveTooltip,
      value: 'cve',
    },
    {
      label: dashboardConstants.cwe,
      formattedLabel: dashboardConstants.cweTooltip,
      value: 'cwe',
    },
    {
      label: dashboardConstants.ghsa,
      formattedLabel: dashboardConstants.ghsaTooltip,
      value: 'ghsa',
    },
    {
      label: dashboardConstants.language,
      formattedLabel: dashboardConstants.languageTooltip,
      value: 'language',
    },
  ];

  dashboardConstants = dashboardConstants;
  hasToggle: boolean = false;
  downIcon = faCaretDown;
  closeIcon = faTimesCircle;

  constructor() {}

  ngOnInit(): void {}

  public toggleDropdown(): void {
    this.hasToggle = !this.hasToggle;
  }

  public onSelect(item: DropDown): void {
    if (this.selectedItem !== item.label) {
      this.hasToggle = false;
      this.selectedItem = item.label;
      this.selectItemEmitter.emit(item);
    }
  }

  public closeDropdown(): void {
    this.hasToggle = false;
  }
}
