// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const foundationSummaryConstants = {
  totalProject: 'Total Project',
  totalProjects: 'Total Projects',
  scannedRepository: 'scannedRepository',
  projectSuccessfullyScanned: 'Project Successfully Scanned',
  projectsSuccessfullyScanned: 'Projects Successfully Scanned',
  scannedProjects: 'scannedProjects',
  projectsUnsuccessfullyScanned: 'Projects Unsuccessfully Scanned',
  projectUnsuccessfullyScanned: 'Project Unsuccessfully Scanned',
  projectPartiallyScanned: 'Project Partially Scanned',
  projectsPartiallyScanned: 'Projects Partially Scanned',
  partiallyScannedProjects: 'partiallyScannedProjects',
  issuesOpen: 'Issues Open',
  totalOpenIssues: 'totalOpenIssues',
  fixableIssues: 'Fixable Issues',
  totalFixableIssues: 'totalFixableIssues',
  issuesFixed: 'Issues Fixed',
  totalFixedIssues: 'totalFixedIssues',
  languages: 'Languages',
  language: 'Language',
  totalLanguages: 'totalLanguages',
  upstreamDependencies: 'Upstream Dependencies',
  upstreamDependency: 'Upstream Dependency',
  totalDependencies: 'totalDependencies',
  typesOfLicencesFound: 'Types of licenses found',
  typeOfLicenceFound: 'Type of license found',
  totalLicenses: 'totalLicenses',
  securitySummary: 'Security Summary',
  autoCompletePlacehoder: 'Start typing the name of a project',
  noResultsFound: 'No results found',
  noProjectAvailable: 'No projects available for this foundation',
  displaying: 'Displaying',
  of: 'of',
  projects: 'projects',
  codeSecrets: 'Code Secrets',
  uniqueNonInclusiveLanguageWordsDetected:
    'Unique Non-Inclusive Language Words Detected',
  uniqueNonInclusiveLanguageWordDetected:
    'Unique Non-Inclusive Language Word Detected',
  vulnerabilities: 'Vulnerabilities',
  uniqueOpenVulnerabilities: 'Unique Open Vulnerabilities',
  uniqueFixableVulnerabilities: 'Unique Fixable Vulnerabilities',
  uniqueVulnerabilitiesFixed: 'Unique Vulnerabilities Fixed',
};
