// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceCharacters',
})
export class ReplaceCharactersPipe implements PipeTransform {
  transform(input: string, replaceFrom: string, replaceInto: string): string {
    if (input && replaceFrom && replaceInto) {
      let regexToReplaceOccurences = new RegExp(`${replaceFrom}`, 'g');

      return (
        input &&
        input.replace(regexToReplaceOccurences, replaceInto).toLowerCase()
      );
    }

    return input;
  }
}
