<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div class="dropdown" lfxClickOutside (clickOutside)="closeDropdown()">
  <div class="flex items-center">
    <div
      class="selected-item"
      [ngClass]="{ disabled: disabled }"
      [title]="selectedItem"
      (click)="toggleDropdown()"
    >
      {{ selectedItem.length === 0 ? dropdownLabel : selectedItem }}
    </div>
    <fa-icon
      *ngIf="selectedItem.length === 0"
      [icon]="downIcon"
      class="arrow"
    ></fa-icon>
    <fa-icon
      *ngIf="selectedItem.length > 0"
      [icon]="closeIcon"
      class="arrow cursor"
      (click)="clearSearch()"
    ></fa-icon>
  </div>
  <div *ngIf="hasToggle" class="dropdown-content">
    <div
      *ngFor="let item of data"
      [title]="item.label"
      (click)="onSelect(item)"
    >
      {{ item.label }}
    </div>
    <div *ngIf="data && data?.length === 0">{{ errorMessage }}</div>
  </div>
</div>
