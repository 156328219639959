<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div class="ng-autocomplete">
  <ng-autocomplete
    [data]="data"
    [(ngModel)]="searchText"
    [minQueryLength]="minQueryLength"
    [searchKeyword]="searchKeyword"
    (selected)="onSelectAutocompleteProject($event)"
    (inputChanged)="onChangeSearch($event)"
    [itemTemplate]="itemTemplate"
    [notFoundTemplate]="notFoundTemplate"
    [notFoundText]="notFoundText"
    [debounceTime]="300"
    [placeholder]="placeholder"
    (inputCleared)="onClearSearch()"
    [isLoading]="isSearchLoading"
    #autoSearch
  >
  </ng-autocomplete>

  <i *ngIf="searchIcon && !searchText" class="fas fa-search search-icon"></i>

  <ng-template #itemTemplate let-item>
    <div [innerHTML]="item[searchKeyword]"></div>
  </ng-template>

  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>
</div>
