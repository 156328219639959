// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { headerConstants, projectCardConstants } from '@config';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Project, ProjectChart } from '@models';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'lfx-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  @Input() project!: Project;
  @Input() loggedIn: boolean | null = false;
  @Output() clickOpenBatchEmitter = new EventEmitter<number>();
  @Output() clickViewProjectEmitter = new EventEmitter<Project>();
  redirectLink: string = '';
  chartLabels: ProjectChart[] = [];
  projectCardConstants = projectCardConstants;
  headerConstants = headerConstants;
  projectGroupType: string = 'Project Group';
  projectType: string = 'Project';
  infoCircleIcon = faInfoCircle;

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.redirectLink =
      environment.lfxHeader.pccLink +
      'project/' +
      this.project.projectSfid +
      '/tools/security/overview';
  }

  public openBadgeUrl(projectCllProjectId: number): void {
    this.clickOpenBatchEmitter.emit(projectCllProjectId);
  }

  public getButtonText(): string {
    let buttonText = projectCardConstants.goToProjects;

    if (this.project.projectType !== this.projectGroupType) {
      buttonText = projectCardConstants.loginToViewDashboard;

      if (this.loggedIn) {
        buttonText = projectCardConstants.viewDashboard;
      }
    }

    return buttonText;
  }

  public viewProjectDetails(): void {
    if (this.loggedIn || this.project.projectType === this.projectGroupType) {
      this.clickViewProjectEmitter.emit(this.project);
    } else {
      this.authService.loginWithRedirect();
    }
  }

  public getAreaChartDataset(): any {
    const foundData: any[] = [];
    const fixableData: any[] = [];
    const fixedData: any[] = [];

    if (!this.project.historicalStatistics) {
      // No data available.
      return null;
    }

    if (this.project.historicalStatistics.length <= 1) {
      // Don't draw graph when only one data point exist.
      return null;
    }

    // As per discussion with Pranab we have added special logic to show graph seperately without collision.
    this.project.historicalStatistics.map((element: any) => {
      this.chartLabels.push(element.month);
      const found = element.openIssue;
      const Fixable = found + element.fixableIssue;
      const Fixed = Fixable + element.fixedIssue;

      foundData.push(found);
      fixableData.push(Fixable);
      fixedData.push(Fixed);
    });

    // Return original as well as negative values array to show graph on both the side of x axis
    return [
      {
        name: 'Fixed-Reverse',
        data: this.changeToNegative(fixedData),
        fillColor: '#074E69',
        color: '#074E69',
        showInLegend: false,
      },
      {
        name: 'Fixed',
        data: fixedData,
        fillColor: '#074E69',
        color: '#074E69',
        showInLegend: false,
      },
      {
        name: 'Fixable-Reverse',
        data: this.changeToNegative(fixableData),
        fillColor: '#1DB4CC',
        color: '#1DB4CC',
        showInLegend: false,
      },
      {
        name: 'Fixable',
        data: fixableData,
        fillColor: '#1DB4CC',
        color: '#1DB4CC',
        showInLegend: false,
      },
      {
        name: 'Found-Reverse',
        data: this.changeToNegative(foundData),
        fillColor: '#B7EDFE',
        color: '#B7EDFE',
        showInLegend: false,
      },
      {
        name: 'Found',
        data: foundData,
        fillColor: '#B7EDFE',
        color: '#B7EDFE',
        showInLegend: false,
      },
    ];
  }

  private changeToNegative = (arr: any) => {
    return arr.reduce((acc: any, val: any) => {
      const negative = val < 0 ? val : val * -1;

      return acc.concat(negative);
    }, []);
  };
}
