<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->
<ol class="breadcrumb">
  <li class="breadcrumb-item" aria-current="page">
    <a class="active" [routerLink]="['']">
      {{ rootBreadcrumb }}
    </a>
  </li>
  <li
    class="breadcrumb-item"
    *ngIf="project && project.parentProjectName && excludeLinuxFoundations()"
  >
    <a
      (click)="redirectToFoundationDetails(project.parentProjectSfid)"
      class="active"
      >{{ project?.parentProjectName }}</a
    >
  </li>
  <li *ngIf="project" class="breadcrumb-item">
    <a (click)="redirectToFoundationProject()" class="active">
      {{ project?.projectName }}
    </a>
  </li>

  <li
    class="breadcrumb-item"
    *ngIf="project && project?.projectType === 'Project Group' && showCommon"
  >
    <a (click)="redirectToFoundationProject()" class="active"
      >{{ project?.projectName }}
      <span> {{ breadcrumbConstants.common }}</span>
    </a>
  </li>
  <li class="breadcrumb-item">
    <a>{{ getPageName() }}</a>
  </li>
</ol>
