// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { breadcrumbConstants } from '@config';
import { Project } from '@models';
@Component({
  selector: 'lfx-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() project!: Project;
  @Input() showCommon: boolean = true;
  breadcrumbConstants = breadcrumbConstants;
  rootBreadcrumb: string = breadcrumbConstants.allProjects;

  constructor(private router: Router) {}

  public redirectToFoundationProject(): void {
    const foundationCommonProject =
      this.project.projectType === 'Project Group' ? true : false;

    if (!this.showCommon) {
      this.router.navigate([''], {
        queryParams: {
          search: this.project.projectName,
        },
      });
    } else if (!this.excludeLinuxFoundations() && !foundationCommonProject) {
      this.router.navigate([''], {
        queryParams: {
          search: this.project.projectName,
        },
      });
    } else {
      const foundationId = foundationCommonProject
        ? this.project.projectSfid
        : this.project.parentProjectSfid;

      this.router.navigate([`/${foundationId}/foundation-details`], {
        queryParams: {
          projectId: this.project.projectSfid,
          search: this.project.projectName,
        },
      });
    }
  }

  public excludeLinuxFoundations(): boolean {
    return !['The Linux Foundation', 'LF Projects, LLC'].includes(
      this.project.parentProjectName
    );
  }

  public redirectToFoundationDetails(foundationId: string): void {
    this.router.navigate([`/${foundationId}/foundation-details`]);
  }

  public getPageName(): string {
    const lastRoute = this.router.url.split('/');
    const rauterName = lastRoute[lastRoute.length - 1].split('?');

    return rauterName[0];
  }
}
