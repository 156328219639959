// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { DecimalPipe } from '@angular/common';
import { barChartConstants } from '@config';
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import { BarChartData, chartType } from '@models';

@Component({
  selector: 'lfx-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() xAxislabelColor!: string;
  @Input() xAxisTitleText!: string;
  @Input() yAxislabelColor!: string;
  @Input() yAxisTitleText!: string;
  @Input() legendItemColor!: string;
  @Input() maxYAxis!: number;
  @Input() legendDirectionRight: boolean = false;
  @Input() showCustomlegend: boolean = false;
  @Input() data!: BarChartData[];
  @Input() labels!: string[];
  @Input() type: chartType = chartType.column;
  @Input() page = 'overview';
  @Input() height: string = '400';

  @ViewChild('barChartContainer') barChartContainerRef!: ElementRef;
  Highcharts: typeof Highcharts = Highcharts;
  barChart: any;
  barChartConstants = barChartConstants;
  constructor(private decimalPipe: DecimalPipe) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    setTimeout(() => this.initBarChart());
  }

  ngAfterViewInit(): void {
    this.initBarChart();
  }

  public ngOnDestroy(): void {
    if (this.barChart) {
      this.barChart.destroy();
    }
  }

  private initBarChart(): void {
    this.barChart = Highcharts.chart(
      this.barChartContainerRef.nativeElement,
      this.barChartOptions()
    );
  }

  private roundToNearest50(num?: number): any {
    if (!num) {
      return null;
    }

    return num + (50 - (num % 50));
  }

  private barChartOptions(): any {
    const that = this;
    const barThickness =
      that.page === 'overview' ? window.innerWidth * 0.032 : 20;

    return {
      chart: {
        type: this.type,
        backgroundColor: 'transparent',
        fontFamily: 'Source Sans Pro',
      },
      title: {
        text: undefined,
      },
      xAxis: {
        title: {
          text: this.xAxisTitleText,
          margin: 10,
          style: {
            color: '#666666',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontFamily: 'Source Sans Pro',
          },
        },
        reversed: false,
        categories: this.labels,
        labels: {
          style: {
            color: this.xAxislabelColor,
            fontSize: '11px',
            fontFamily: 'Open Sans',
            textTransform: 'uppercase',
            fontWeight: 'bold',
          },
        },
      },
      yAxis: {
        min: 0,
        max: this.roundToNearest50(this.maxYAxis),
        title: {
          text: this.yAxisTitleText,
          margin: 10,
          style: {
            color: '#666666',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontFamily: 'Source Sans Pro',
          },
        },
        labels: {
          style: {
            color: this.yAxislabelColor,
            fontSize: '11px',
            fontFamily: 'Open Sans',
            fontWeight: 'bold',
          },
        },
      },
      legend: {
        enabled: !this.showCustomlegend,
        className: 'legend-indicator',
        reversed: true,
        itemStyle: {
          color: this.legendItemColor,
          fontSize: '11px',
          fontFamily: 'Open Sans',
          fontWeight: 'normal',
        },
        verticalAlign: this.legendDirectionRight ? 'top' : 'bottom',
        itemMarginBottom: this.legendDirectionRight ? 15 : 0,
        layout: this.legendDirectionRight ? 'vertical' : 'horizontal',
        align: this.legendDirectionRight ? 'right' : 'center',
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          pointWidth: barThickness, // for chart bar width
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#929699',
        className: 'bar-chart-tooltip',
        fontFamily: 'Source Sans Pro',
        formatter: function () {
          const tooltipData: any = this;
          let htmlTooltip: string = '';

          if (that.page === 'foundation-summary') {
            htmlTooltip = that.foundationSummaryTooltip(tooltipData);
          } else {
            htmlTooltip = that.overviewPageTooltip(tooltipData);
          }

          return htmlTooltip;
        },
        shared: true,
        useHTML: true,
      },
      series: [...this.data].reverse(),
    };
  }

  private foundationSummaryTooltip(tooltipData: any) {
    let htmlTooltip: string = `<div class="foundation-summary-tooltip"><div class="head">${tooltipData.x}</div>`;

    tooltipData &&
      tooltipData.points &&
      tooltipData.points.forEach((ele: any) => {
        const severity =
          ele && ele.point && ele.point.series && ele.point.series.name;

        const val = (ele && ele.y) || 0;
        const severityValue = this.decimalPipe.transform(val, '2.');

        htmlTooltip += `<div>
              <span class="color-box ${
                severity && severity.toLowerCase()
              }"></span><span class="severity-name">${severity}:${severityValue}</span></div>`;
      });
    htmlTooltip += `</div>`;

    return htmlTooltip;
  }

  private overviewPageTooltip(tooltipData: any) {
    let sumOfSeverityValues: any = 0;
    let val: any = 0;

    tooltipData.points.forEach((ele: any) => {
      val += ele.y;
    });
    sumOfSeverityValues = this.decimalPipe.transform(val, '2.');

    let htmlTooltip: string = `<div class="overview-tooltip">`;

    htmlTooltip += `<div class="head"><span class="head-value">${sumOfSeverityValues}</span> Total Issues</div>`;
    htmlTooltip += `<div class="tooltip-box">`;

    tooltipData &&
      tooltipData.points &&
      tooltipData.points.reverse().forEach((ele: any) => {
        const severity =
          ele && ele.point && ele.point.series && ele.point.series.name;

        const val = (ele && ele.y) || 0;
        const severityValue = this.decimalPipe.transform(val, '2.');

        htmlTooltip += `<div class="data">
              <span class="color-circle ${
                severity && severity.toLowerCase()
              }"></span><span class="severity-value">${severityValue}</span></div>`;
      });

    htmlTooltip += `</div></div>`;

    return htmlTooltip;
  }
}
