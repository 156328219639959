<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div
  id="project-filter"
  class="dropdown"
  lfxClickOutside
  (clickOutside)="closeDropdown()"
  (click)="toggleDropdown()"
>
  <div class="flex items-center">
    <div class="selected-item">
      {{ selectedItem }}
    </div>
    <fa-icon [icon]="downIcon" class="arrow"></fa-icon>
  </div>
  <div *ngIf="hasToggle" class="dropdown-content">
    <div
      *ngFor="let item of data"
      [id]="item.label"
      [title]="item.formattedLabel"
      (click)="onSelect(item)"
    >
      {{ item.label }}
    </div>
  </div>
</div>
