// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { tz } from 'moment-timezone';

@Pipe({
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  transform(
    value: any,
    isDateString?: boolean,
    dateFormat: string = 'MMMM, DD YYYY - HH.mm',
    timeZone: boolean = true
  ): any {
    if (!value) {
      return '--';
    }

    if (isDateString) {
      value = moment(value).unix();
    }

    const dateTimeInClientTimezone = DateTimeToClientTimeZone(value);

    return (
      dateTimeInClientTimezone.date.format(dateFormat) +
      ' ' +
      (timeZone ? dateTimeInClientTimezone.timeZoneAbbr : '')
    );
  }
}

function DateTimeToClientTimeZone(timestamp: number) {
  const guess = tz.guess(true);
  const zone = tz.zone(guess);

  timestamp = timestamp * 1000;

  return {
    date: moment.tz(timestamp, zone!.name),
    dateString: moment.tz(timestamp, zone!.name).format(),
    timeZoneAbbr: zone!.abbr(timestamp),
  };
}
