// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { dashboardConstants } from '@config';

@Component({
  selector: 'lfx-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() isSearchLoading: boolean = false;
  @Input() placeholder: string = '';
  @Input() searchText: string = '';
  @Input() searchIcon: boolean = false;
  @Input() width: string = '600';
  @Input() minQueryLength: number = 2;
  @Input() searchKeyword: string = 'projectName';
  @Input() notFoundText: string = dashboardConstants.noResultsFound;
  @Output() searchTextEmitter = new EventEmitter<string>();
  @Output() resetSearchEmitter = new EventEmitter();
  @Output() selectedProjectEmitter = new EventEmitter();
  @ViewChild('autoSearch') autoSearch!: any;

  dashboardConstants = dashboardConstants;

  constructor() {}

  ngOnInit(): void {}

  public onSelectAutocompleteProject(item: any): void {
    this.selectedProjectEmitter.emit(item);
  }

  public onChangeSearch(searchText: string): void {
    if (searchText.length > 0) {
      this.searchText = searchText && searchText.trim();
      this.searchTextEmitter.emit(this.searchText);
    } else {
      this.autoSearch.close();
    }
  }

  public onClearSearch(): void {
    this.searchText = '';
    this.autoSearch.close();
    this.resetSearchEmitter.emit();
  }
}
