// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const vulnerabilitiesSeverityTableViewConstants = {
  allSeverity: 'All Severity',
  criticalSeverity: 'Critical Severity',
  highSeverity: 'High Severity',
  mediumSeverity: 'Medium Severity',
  lowSeverity: 'Low Severity',
  vulnerabilities: 'Vulnerabilities',
  vulnerability: 'Vulnerability',
  severity: 'Severity',
  cwe: 'CWE',
  cve: 'CVE',
  yes: 'Yes',
  no: 'No',
  ghsa: 'GHSA',
  cvssScore: 'CVSS Score',
  state: 'State',
  manage: 'Manage',
  status: 'status',
  fixable: 'Fixable',
  notFixable: 'Not Fixable',
  viewDetails: 'View Details',
  vulnerabilitiesNotFound: 'No vulnerabilities detected.',
  eyeIconTooltip:
    "Use this button to dismiss this vulnerability (if it is not fixable or you don't intend to fix it or if it is minor). It won't be counted towards total vulnerability count",
};
