// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export interface User {
  id: string;
  auth0Id: string;
  type: string;
  firstName: string;
  lastName: string;
  fullName: string;
  avatarUrl: string;
  timezone: string;
  emails: Email[];
  username: string;
  modifiedDate: string;
  createdDate: string;
  permissions: UserPermission[];
  socialAccounts?: Identity[];
  roles: string[];
}

interface Email {
  emailAddress: string;
  emailType: string;
  active: boolean;
  isDeleted: boolean;
  isPrimary: boolean;
  isVerified?: boolean;
}

interface UserPermission {
  context: string;
  actions: string[];
  allowed: boolean;
  resource: string;
  scopes: UserScope[];
}

interface UserScope {
  id: string[];
  role: string;
  type: string;
}

enum SocialAuthProviders {
  google = 'google-oauth2',
  github = 'github',
  facebook = 'facebook',
  linkedin = 'linkedin',
}

interface Identity {
  userId: string;
  provider: SocialAuthProviders;
  connection: string;
  isSocial: boolean;
}

export interface PreferenceInput {
  userSfid: string;
  preferenceId: string;
}

export interface UserPreference {
  id: string;
  appName: string;
  name: string;
  description: string;
  system: boolean;
  type: string;
  value: string;
  lastModifiedDate: string;
  createdDate: string;
  createdById: string;
  lastModifiedById: string;
}

export interface AddPreferenceInput {
  userSfid: string;
  body: AddPreferenceBody;
}

export interface UpdatePreferenceInput {
  userSfid: string;
  preferenceId: string;
  body: AddPreferenceBody;
}

export interface AddPreferencePayload {
  code: string;
  message: string;
  success: boolean;
  preference?: UserPreference;
}

export interface PreferencesInput {
  userSfid: string;
  pageSize?: number;
  offset?: number;
  filter?: String;
}

export interface AddPreferenceBody {
  appName?: string;
  description?: string;
  name: string;
  system?: boolean;
  type?: string;
  value?: any;
}
export interface UpdatePreferencePayload {
  code: string;
  message: String;
  success: boolean;
  preference?: UserPreference;
}

export interface DeletePreferenceInput {
  userSfid: string;
  preferenceId: string;
}

export interface DeletePreferencePayload {
  code: string;
  message: string;
  success: boolean;
}
