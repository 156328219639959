// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const codeSecretsConstants = {
  codeSecretsDetected: 'code secrets detected',
  codeSecretsExposedAndNotifications: 'code secrets exposed & notifications',
  codeSecretsExposed: 'code secrets exposed',
  notifications: 'notifications',
  detectedOn: 'Detected On',
  description: 'Description',
  alertType: 'Alert Type',
  eventType: 'Event Type',
  repository: 'Repository',
  repoUrl: 'Repo Url ',
  sourceRepoUrl: 'Source Repo Url ',
  lastUpdated: 'Last Updated ',
  repoType: 'Repo Type',
  sourceType: 'Source Type',
  developer: 'Developer',
  status: 'Status',
  actionTaken: 'Action Taken',
  takeAction: 'Take Action',
  availableActions: 'available actions',
  file: 'file',
  commit: 'commit',
  name: 'name',
  privateKey: 'private key',
  secretHash: 'secret hash',
  organization: 'organization',
  commitId: 'commit id',
  commitTime: 'commit time',
  commitedBy: 'commited by',
  message: 'message',
  resolve: 'Resolve',
  ignore: 'Ignore',
  falsePositive: 'False Positive',
  ignored: 'Ignored',
  active: 'Active',
  reviewed: 'Reviewed',
  dismissed: 'dismissed',
  notImportant: 'not_important',
  deleted: 'deleted',
  noCodeSecretsAlertsAvailable: 'No alerts available',
  noCodeSecretsEventsAvailable: 'No events available',
  line: 'Line',
  cols: 'Cols',
  to: 'to',
  codeSecretTypes: 'code secret types',
  alertTypeFilters: 'Alert Type Filters',
  eventTypeFilters: 'Event Type Filters',
  repositoryFilters: 'Repository Filters',
  developerFilters: 'Developer Filters',
  actionTakenFilters: 'Action Taken Filters',
  nonInclusiveLanguageFilters: 'Non Inclusive language Filters',
  time: 'Time',
  noCodeSecretsAvailable: 'No code secrets available.',
  nonInclusiveLanguage: 'Non Inclusive language',
  githubCloud: 'github_cloud',
};
