<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->
<div class="project-search">
  <div class="ng-autocomplete">
    <fa-icon class="fa-search-icon" [icon]="faSearchIcon"></fa-icon>
    <ng-autocomplete
      *ngIf="searchKeyword === 'projectName'"
      [data]="data"
      id="project-search-autocomplete"
      [(ngModel)]="searchText"
      [minQueryLength]="minQueryLength"
      [searchKeyword]="'projectName'"
      (selected)="onSelectAutocompleteProject($event)"
      (inputChanged)="onChangeSearch($event)"
      [itemTemplate]="itemTemplate"
      [notFoundTemplate]="notFoundTemplate"
      [notFoundText]="notFoundText"
      [debounceTime]="300"
      [placeholder]="placeholder"
      (inputCleared)="onClearSearch()"
      [isLoading]="isSearchLoading"
      #autoSearch
    >
    </ng-autocomplete>

    <ng-autocomplete
      *ngIf="searchKeyword !== 'projectName'"
      [data]="data"
      [(ngModel)]="searchText"
      [minQueryLength]="minQueryLength"
      (selected)="onSelectAutocompleteProject($event)"
      (inputChanged)="onChangeSearch($event)"
      [debounceTime]="300"
      [placeholder]="placeholder"
      (inputCleared)="onClearSearch()"
      [isLoading]="isSearchLoading"
      #autoSearch
    >
    </ng-autocomplete>

    <i class="fas fa-search search-icon"></i>

    <ng-template #itemTemplate let-item>
      <div [innerHTML]="item[searchKeyword]"></div>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
      <div [innerHTML]="notFound"></div>
    </ng-template>
  </div>
</div>
