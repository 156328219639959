<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div
  #wordChartContainer
  *ngIf="wordCloudData"
  class="flex h-200 border border-gray-0e7"
></div>

<div *ngIf="!data || data.length === 0" class="tab-error">
  {{ wordCloudConstants.wordCloudError }}
</div>
