// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { projectCardConstants } from '@config';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'lfx-area-spline-chart',
  templateUrl: './area-spline-chart.component.html',
  styleUrls: ['./area-spline-chart.component.scss'],
})
export class AreaSplineChartComponent implements AfterViewInit, OnDestroy {
  @Input() public height: number = 120;
  @Input() public width: number = 330;
  @Input() series!: any[];
  @ViewChild('areaChartContainer') public chartRef!: ElementRef;

  projectCardConstants = projectCardConstants;
  highcharts = Highcharts;
  chart: any;

  constructor() {}

  ngAfterViewInit(): void {
    if (this.series) {
      this.initilizeAreaChart();
    }
  }

  initilizeAreaChart(): void {
    const chartOptions: any = {
      chart: {
        type: 'areaspline',
        height: this.height,
        width: this.width,
        renderTo: this.chartRef.nativeElement,
      },
      title: {
        text: null,
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
          marker: {
            enabled: false,
          },
        },
        series: {
          enableMouseTracking: false,
        },
      },
      tooltip: {
        enabled: false,
      },
      series: this.series,
    };

    this.chart = new Highcharts.Chart(chartOptions);
  }

  public ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
}
