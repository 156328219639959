// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const alertTypesColorCodes: string[] = [
  '#D1C0E2',
  '#6E4B9F',
  '#63A99E',
  '#E19E33',
  '#C4A8D0',
  '#6059A7',
  '#6BB091',
  '#E78B34',
  '#B58FC2',
  '#5567BB',
  '#75B97C',
  '#E47931',
  '#A876B1',
  '#4E7AC5',
  '#8CBC66',
  '#E26330',
  '#9A63A6',
  '#4A8BCB',
  '#A7BE54',
  '#E24526',
  '#8C4E99',
  '#5196B7',
  '#BEBD47',
  '#DB2518',
  '#51A0B1',
  '#CFB73D',
  '#B7221E',
  '#54A8AA',
  '#DDAB38',
  '#94211A',
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
  '#e8274b',
  '#814ccc',
  '#882b0f',
  '#02f88c',
  '#315665',
  '#b9d9ff',
  '#3aa2b5',
  '#64c800',
  '#c7d7dc',
  '#9dc3ff',
  '#1797c0',
  '#2acca8',
  '#5a8164',
  '#101f1f',
  '#aa2afe',
  '#9400ff',
  '#a957b0',
  '#6e4c13',
  '#ff0000',
  '#1ac620',
  '#6594b9',
  '#1c3552',
  '#ff5000',
  '#c1f12e',
  '#f7523f',
  '#cd6400',
  '#d4bec1',
  '#2f2530',
  '#555555',
  '#178600',
  '#f34b7d',
  '#dfa535',
  '#8dc63f',
  '#ccccff',
  '#db901e',
  '#6a40fd',
  '#3f85af',
  '#e4e6f3',
  '#db5855',
  '#244776',
  '#ed2cd6',
  '#e6efbb',
  '#3fb68b',
  '#b5314c',
  '#b0ce4e',
  '#000100',
  '#244776',
  '#563d7c',
  '#3a4e3a',
  '#ba595e',
  '#ffec25',
  '#00b4ab',
  '#003a52',
  '#dfafff',
  '#447265',
  '#384d54',
  '#cca760',
  '#6c616e',
  '#ccce35',
  '#913960',
  '#8a1267',
  '#4d6977',
  '#6e4a7e',
  '#60b5cc',
  '#c065db',
  '#fff4f3',
  '#a78649',
  '#b83998',
  '#b845fc',
  '#572e30',
  '#636746',
  '#7b9db4',
  '#14253c',
  '#c37240',
  '#88ccff',
  '#341708',
  '#4d41b1',
  '#0050b2',
  '#00cafe',
  '#5f021f',
  '#d08cf2',
  '#71b417',
  '#ffc766',
  '#355570',
  '#fb855d',
  '#5b2063',
  '#c1ac7f',
  '#f0a9f0',
  '#00add8',
  '#88562a',
  '#82937f',
  '#ff0000',
  '#e69f56',
];
