// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const tabViewConstants = {
  overview: 'overview',
  vulnerabilities: 'vulnerabilities',
  dependencyTree: 'dependency tree',
  licenses: 'licenses',
  codeSecrets: 'code secrets',
  nonInclusiveLanguages: 'non inclusive language',
};
