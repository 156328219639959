// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const vulnerabilitiesConstants = {
  vulnerabilities: 'Vulnerabilities',
  infoIconToolTip:
    'Issue list in this table shows all open issues found in the last scan.',
  downloadCSV: 'Download CSV',
  critical: 'Critical',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
};
