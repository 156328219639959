// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const overviewConstants = {
  codeSecretsDetected: 'Code Secrets Detected',
  nonInclusiveLanguage: 'Non Inclusive Language',
  invalidProject: 'Invalid Project',
  projectIdNotFound: 'The project id does not exist in our database.',
  repositoryError: 'Repository not found',
  repositoryNotFound: 'Repository does not exist for selected project.',
  recentAlert: 'Recent Alerts',
};
