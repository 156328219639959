// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const dependencyTreeConstants = {
  dependencyTree: 'Dependency Tree',
  downloadCSV: 'Download CSV',
  critical: 'Critical',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  viewCritical: 'View Critical',
  viewHigh: 'View High',
  viewMedium: 'View Medium',
  viewLow: 'View Low',
  allDependencies: 'All Dependencies',
  vulnerabilitiesOnly: 'Vulnerabilities Only',
  manifestFileError: 'No manifest file available or no dependencies detected',
  dependencyError: 'Error ocurred during fetching dependencies',
  selectManifestFile: 'Select a repository, then select a manifest file',
  noRepositoryFound: 'No repository found.',
  manifestFileFound: 'No manifest file found',
  selectManifest: 'select a manifest file',
  vulnerabilitiesOnlyError:
    'No manifest file available or no vulnerabilities detected',
  back: 'Back',
};
