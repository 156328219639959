// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const recentAlertsConstants = {
  recentAlerts: 'Recent Alerts',
  passwordAssignment: 'Password Assignment',
  secretAssignment: 'Secret Assignment',
  aWSAccessKeyID: 'AWS Access Key ID',
  passwordInURL: 'Password in URL',
  privateKey: 'Private Key',
  aWSSecretKey: 'AWS Secret Key',
  gitHubTokenSecret: 'GitHub Token/Secret',
  gitLabAccessToken: 'GitLab Access Token',
  credentialAssignment: 'Credential Assignment',
  aWSAccountID: 'AWS Account ID',
  terraformStateFile: 'Terraform State File',
  gitHubOAuthClientID: 'GitHub OAuth Client ID',
  googleOAuthAccessToken: 'Google OAuth Access Token',
  slackWebhook: 'Slack Webhook',
  googleApiKey: 'Google Api Key',
  jWTToken: 'JWT Token',
  pGPPrivateKey: 'PGP Private Key',
  networkTrafficCaptureFile: 'Network Traffic Capture File',
  nPMRCAuth: 'NPMRC Auth',
  javaKeystoreFile: 'Java Keystore File',
  terraformAccessToken: 'Terraform Access Token',
  secretFunction: 'Secret Function',
  passwordFunction: 'Password Function',
  secretInURLParam: 'Secret In URL Param',
  basicAuthHeader: 'Basic Auth Header',
  environmentConfigurationFile: 'Environment Configuration File',
  passwordURLParam: 'Password in URL Param',
  passwordXML: 'Password in XML',
  secretXML: 'Secret in XML',
  googleOAuth: 'Google OAuth',
  cryptographicKeyBundle: 'Cryptographic Key Bundle',
  gnomeKeyringDatabaseFile: 'Gnome Keyring Database File',
  npmAccessTokenBase64: 'Npm Access Token Base64',
  boxClientIDSecret: 'Box Client ID Secret',
  apacheHTpasswdFile: 'Apache HTpasswd File',
  azureActiveDirectoryClientSecret: 'Azure Active Directory Client Secret',
  gitHubBase64TokenSecret: 'GitHub Base64 Token/Secret',
  githubPersonalAccessToken: 'Github personal access token',
  repositories: 'repositories',
  repository: 'repository',
  foundIn: 'found in',
  no: 'No',
  notFound: 'No recent alerts found.',
};
