// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const barChartConstants = {
  issuesOpen: 'Issues open',
  issuesFixable: 'Issues Fixable',
  issuesFixed: 'Issues Fixed',
  critical: 'Critical',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
};
