<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<!-- Card for Project-->
<ng-container
  *ngIf="project && project.projectType === projectType"
  [ngTemplateOutlet]="card"
  [ngTemplateOutletContext]="{ className: '' }"
></ng-container>

<!-- Card for Foundation-->
<div
  *ngIf="project && project.projectType === projectGroupType"
  class="stacked-cards"
>
  <ng-container
    [ngTemplateOutlet]="card"
    [ngTemplateOutletContext]="{ className: 'stack top-0 left-0 real-card' }"
  >
    <ng-container
      [ngTemplateOutlet]="cardSkeleton"
      [ngTemplateOutletContext]="{ className: 'stack !top-1.5 !left-1.5' }"
    >
      <ng-container
        [ngTemplateOutlet]="cardSkeleton"
        [ngTemplateOutletContext]="{ className: 'stack top-3 left-3' }"
      ></ng-container>
    </ng-container>
  </ng-container>
</div>

<!-- Card with actual data-->
<ng-template #card let-className="className">
  <div [ngClass]="className" class="project-card-container">
    <div class="top-line"></div>

    <div class="project-card-header">
      <div *ngIf="project.logoUrl" class="flex justify-center">
        <img class="w-48 h-14 pb-1" [src]="project.logoUrl" />
      </div>
      <div *ngIf="!project.logoUrl" class="flex justify-center">
        <div class="default-logo">{{ project.projectName | uppercase }}</div>
      </div>
      <div class="project-name" [title]="project.projectName">
        {{ project.projectName }}
      </div>
      <div
        *ngIf="project.projectCiiProjectId !== 0"
        class="flex justify-center"
      >
        <ng-container
          [ngTemplateOutlet]="ciiBestPracticeBatch"
          [ngTemplateOutletContext]="{ project: project }"
        ></ng-container>
      </div>

      <div
        *ngIf="
          project.projectType === 'Project Group' &&
          project.totalOssfBadgetsProject > 0
        "
        class="flex justify-center"
      >
        <div class="project-group-badge opeSSF-gradient">
          {{ project.totalOssfBadgetsProject }}
          {{ projectCardConstants.outOf }} {{ project.totalProjects }}
          {{ projectCardConstants.projectsOpenSSFBadges }}
        </div>
      </div>
    </div>

    <div class="project-card-body">
      <div class="text-xs text-center text-gray-9f9f uppercase font-normal">
        {{ projectCardConstants.totalVulnerabilities }}
      </div>
      <ng-container [ngTemplateOutlet]="totalVulnerabilities"></ng-container>
      <lfx-area-spline-chart
        [series]="getAreaChartDataset()"
      ></lfx-area-spline-chart>
      <div [ngTemplateOutlet]="nonInclusiveAndCodeSecret"></div>
      <div [ngTemplateOutlet]="repoStatus"></div>
    </div>

    <div class="project-card-footer">
      <button class="button" (click)="viewProjectDetails()">
        {{ getButtonText() }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #ciiBestPracticeBatch let-project="project">
  <img
    class="cursor-pointer"
    (click)="openBadgeUrl(project?.projectCiiProjectId)"
    src="https://bestpractices.coreinfrastructure.org/projects/{{
      project?.projectCiiProjectId
    }}/badge"
  />
</ng-template>

<ng-template #totalVulnerabilities>
  <div class="flex justify-center space-x-4 mt-2">
    <div class="flex space-x-2">
      <div class="w-4 h-4 bg-blue-dfe"></div>
      <div>
        <div
          class="text-sm font-semibold leading-3"
          [title]="project?.vulnerabilityStatistics?.total?.open | formatNumber"
        >
          {{ project?.vulnerabilityStatistics?.total?.open | lfxShortNumber }}
        </div>
        <div class="text-xs-10 uppercase">
          {{ projectCardConstants.found }}
        </div>
      </div>
    </div>
    <div class="flex space-x-2">
      <div class="w-4 h-4 bg-blue-4cc"></div>
      <div>
        <div
          class="text-sm font-semibold leading-3"
          [title]="
            project?.vulnerabilityStatistics?.total?.openFixable | formatNumber
          "
        >
          {{
            project?.vulnerabilityStatistics?.total?.openFixable
              | lfxShortNumber
          }}
        </div>
        <div class="text-xs-10 uppercase">
          {{ projectCardConstants.fixable }}
        </div>
      </div>
    </div>
    <div class="flex space-x-2">
      <div class="w-4 h-4 bg-blue-e69"></div>
      <div>
        <div
          class="text-sm font-semibold leading-3"
          [title]="
            project?.vulnerabilityStatistics?.total?.fixed | formatNumber
          "
        >
          {{ project?.vulnerabilityStatistics?.total?.fixed | lfxShortNumber }}
        </div>
        <div class="text-xs-10 uppercase">
          {{ projectCardConstants.fixed }}
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #nonInclusiveAndCodeSecret>
  <div class="flex justify-center divide-x-1 my-1">
    <!-- <div class="flex items-center space-x-1 w-2/4 px-2 justify-end">
      <div
        class="text-lg font-semibold"
        [title]="project?.codeSecretsAlertCount | formatNumber"
      >
        {{ project?.codeSecretsAlertCount | lfxShortNumber }}
      </div>
      <div class="text-xs-8 w-9 uppercase">
        {{ projectCardConstants.codeSecrets }}
      </div>
    </div>
    <div class="flex items-center space-x-1 w-2/4 px-2 justify-start">
      <div
        class="text-lg font-semibold"
        [title]="project?.nonInclusiveAlertWordCount | formatNumber"
      >
        {{ project?.nonInclusiveAlertWordCount | lfxShortNumber }}
      </div>
      <div class="text-xs-8 w-16 uppercase">
        {{ projectCardConstants.nonInclusiveInstances }}
      </div>
    </div> -->
    <div class="text-xs">
      {{ headerConstants.blueBracketError }}
    </div>
  </div>
</ng-template>

<ng-template #repoStatus>
  <div class="bg-pink-af9 flex justify-center h-10 w-full mt-3 divide-x-1 py-2">
    <div class="flex justify-center items-center p-1.5 space-x-1 w-1/3">
      <span
        class="text-sm-15 font-semibold"
        [title]="
          project?.repositoryStatistics?.totalRepositories | formatNumber
        "
        >{{
          project?.repositoryStatistics?.totalRepositories | lfxShortNumber
        }}</span
      >
      <span class="text-xs-8 uppercase">
        {{ projectCardConstants.totalRepos }}</span
      >
    </div>
    <div class="flex justify-center items-center p-1.5 space-x-1 w-1/3">
      <span
        class="text-sm-15 font-semibold"
        [title]="
          project?.repositoryStatistics?.scannedRepositories | formatNumber
        "
        >{{
          project?.repositoryStatistics?.scannedRepositories | lfxShortNumber
        }}</span
      >
      <span class="text-xs-8 uppercase">
        {{ projectCardConstants.scannedRepos }}</span
      >
      <fa-icon
        class="tooltip-icon"
        [icon]="infoCircleIcon"
        [popper]="popperScannedReposContent"
        [popperAppendTo]="'body'"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="true"
      >
      </fa-icon>

      <popper-content #popperScannedReposContent>
        {{ projectCardConstants.scannedReposTooltip }}
      </popper-content>
    </div>
    <div class="flex justify-center items-center p-1.5 space-x-1 w-1/3">
      <span
        class="text-sm-15 font-semibold"
        [title]="
          project?.repositoryStatistics?.disabledRepositories | formatNumber
        "
        >{{
          project?.repositoryStatistics?.disabledRepositories | lfxShortNumber
        }}</span
      >
      <span class="text-xs-8 uppercase">
        {{ projectCardConstants.disabledRepos }}</span
      >
      <fa-icon
        class="tooltip-icon"
        [icon]="infoCircleIcon"
        [popper]="popperDisabledReposContent"
        [popperAppendTo]="'body'"
        [popperHideOnClickOutside]="true"
        [popperHideOnScroll]="true"
      >
      </fa-icon>

      <popper-content #popperDisabledReposContent>
        {{ projectCardConstants.disabledReposTooltip }}
        <a [href]="redirectLink" target="_blank" class="text-primary">{{
          projectCardConstants.settingsPage
        }}</a>
      </popper-content>
    </div>
  </div>
</ng-template>

<!-- Skeleton used for foundation card -->
<ng-template #cardSkeleton let-className="className">
  <div [ngClass]="className" class="project-card-container">
    <div class="top-line"></div>

    <div class="project-card-header"></div>

    <div class="project-card-body"></div>

    <div class="project-card-footer"></div>
  </div>
</ng-template>
