// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const projectCriticalityConstants = {
  projectCriticality: 'Project Criticality',
  score: 'Score',
  scoreTooltip:
    'This is an aggregate score of weighted sum of the signal values of all the packages included in a project.',
  contributor: '# Contributors',
  contributorTooltip:
    'This value shows a count of distinct project contributors (with commits). Different contributors involvement indicates projects importance.',
  projectYears: 'Project Years',
  projectYearsTooltip:
    'This check tells you the projects age. When was the project first created.',
  recentReleases: 'Recent Releases',
  recentReleasesTooltip:
    'This value shows the number of releases in the last year. Frequent releases indicates user dependency.',
  monthsSinceLastUpdate: 'Months Since Last Update',
  monthsSinceLastUpdateTooltip:
    'This check determines whether the project is actively maintained. If the project is archived, it receives the lowest score. If there is at least one commit per week during the previous 90 days, the project receives the highest score.',
  dependents: 'Dependents',
  dependentsTooltip:
    'This check tries to determine if the project is an application that has declared and pinned its dependencies. A "pinned dependency" is a dependency that is explicitly set to a specific version instead of allowing a range of versions.',
};
