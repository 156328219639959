// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const projectCardConstants = {
  loginToViewDashboard: 'Login To View Dashboard',
  goToProjects: 'Go to projects',
  viewDashboard: 'View Dashboard',
  totalVulnerabilities: 'Total Vulnerabilities',
  found: 'Found',
  fixable: 'Fixable',
  fixed: 'Fixed',
  graphErrorMessage:
    'There are not enough data points to render a vulnerabilities graph.',
  codeSecrets: 'Code Secrets',
  nonInclusiveInstances: 'Non Inclusive Instances',
  totalRepos: 'Total Repos',
  scannedRepos: 'Scanned Repos',
  scannedReposTooltip:
    'Not all repositories will be scanned since some repositories do not have the proper manifest file.',
  disabledRepos: 'Disabled Repos',
  disabledReposTooltip:
    'These are repositories that have been removed from scanning from the',
  settingsPage: 'settings page',
  areaChartError:
    'There are not enough data points to render a vulnerabilities graph.',
  outOf: 'out of',
  projectsOpenSSFBadges: ' Projects have OpenSSF Badges',
};
