<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->
<div class="page-error-code">{{ pageNotFoundConstants.errorCode404 }}</div>
<div class="page-error-title">{{ pageNotFoundConstants.title }}</div>
<div class="page-error-subtitle">
  {{ pageNotFoundConstants.subtitle }}
</div>
<div class="flex justify-center mt-8">
  <button (click)="redirectToDashboard()">
    {{ pageNotFoundConstants.returnHomePage }}
  </button>
</div>
