<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<!-- Desktop view -->
<div class="hidden md:block">
  <div class="pagination-container">
    <div class="pagination-results-info">{{ currentPageDetails() }}</div>
    <div *ngTemplateOutlet="pagination"></div>
  </div>
</div>

<!-- Mobile -->
<div class="block md:hidden">
  <div class="mobile-pagination-container">
    <div class="mobile-pagination-results-info">
      {{ currentPageDetails() }}
    </div>
    <div *ngTemplateOutlet="pagination"></div>
  </div>
</div>

<ng-template #pagination>
  <div class="navigation-section">
    {{ tablePaginatorConstants.page }}
    <input
      type="text"
      class="page-input"
      [(ngModel)]="currentPage"
      (keyup)="jumpToPage()"
      [disabled]="totalRecords === 0"
      #paginatorInput
    />
    {{ tablePaginatorConstants.of }}
    {{ currentPageNumber() }}
    <div class="navigation">
      <div class="prev-arrow-btn" (click)="prevBtnClick()">
        <fa-icon [icon]="caretLeftIcon"></fa-icon>
      </div>
      <div class="next-arrow-btn" (click)="nextBtnClick()">
        <fa-icon [icon]="caretRightIcon"></fa-icon>
      </div>
    </div>
  </div>
</ng-template>
