<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div *ngIf="version === 'v1'" class="powered-by-container">
  <div class="capitalize text-xs whitespace-nowrap text-gray-400 mr-2">
    Powered by:
  </div>
  <div
    *ngIf="logoType === 'snyk' || logoType === 'both'"
    class="w-16 h-10 bg-no-repeat bg-contain bg-center mt-1 mr-2 float-right"
    style="background-image: url('../../../../assets/images/snyk.png')"
  ></div>
  <div
    *ngIf="logoType === 'blueBracket' || logoType === 'both'"
    class="w-32 h-10 float-right items-center"
  >
    <div
      class="bg-no-repeat bg-contain bg-center h-10 w-32"
      style="
        background-image: url('../../../../assets/images/powered-by-blubracket.svg');
      "
    ></div>
  </div>
  <div class="clear-both"></div>
</div>

<div *ngIf="version === 'v2'" class="mb-5">
  <div class="flex justify-center item-center space-x-2">
    <div class="line mt-1"></div>
    <div class="text-xs-10 text-center mb-1">Powered by:</div>
    <div class="line mt-1"></div>
  </div>
  <div class="powered-by-logos-container">
    <div
      id="snyk-logo"
      class="logo"
      style="background-image: url('../../../../assets/images/snyk_v2.png')"
    ></div>
    <!-- <div class="vertical-separator"></div> -->
    <!-- <div
      id="bluebracket-logo"
      class="logo bluebracket"
      style="
        background-image: url('../../../../assets/images/bluebracket_v2.png');
      "
    ></div> -->
    <div class="vertical-separator"></div>
    <div
      id="openssf-logo"
      class="logo mt-1"
      style="background-image: url('../../../../assets/images/openssf_v2.png')"
    ></div>
  </div>
  <div class="flex justify-center">
    <div class="line"></div>
    <div class="line"></div>
    <div class="line"></div>
  </div>
</div>
