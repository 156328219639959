// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const dashboardConstants = {
  securityLeaderboard: 'Security Leaderboard',
  vulnerabilitiesDetected: 'Vulnerabilities Detected',
  recommendedFixes: 'Recommended Fixes',
  uniqueVulnerabilities: 'Unique Vulnerabilities',
  totalVulnerabilities: 'Total Vulnerabilities',
  vulnerabilitiesFixed: 'Unique Vulnerabilities Fixed out of',
  scannedRepositories: 'Repositories Successfully Scanned out of',
  topFixableVulnerabilities: 'Top 10 Most Impactful Fixable Vulnerabilities',
  topFixingVulnerabilities:
    'Top 10 Projects Most Active In Fixing Vulnerabilities',
  topRepositoriesScanned: 'Top 10 Projects By Repositories Scanned',
  autoCompletePlaceholder:
    'Search Projects by Name, Slug, CVE, CWE, GHSA, and Language...',
  noResultsFound: 'No results found',
  noProjectAvailable: 'No foundations or projects are available.',
  fixed: 'Fixed',
  impacting: 'Impacting',
  repos: 'repos',
  displaying: 'Displaying',
  of: 'of',
  projects: 'projects',
  outOf: 'Out of',
  cve: 'CVE',
  cwe: 'CWE',
  ghsa: 'GHSA',
  language: 'Languages',
  project: 'Project',
  projectTooltip: 'Search by Project Name or Project Slug',
  projectPlaceholder: 'Search by Project Name or Project Slug…',
  cvePlaceholder: 'Search by CVE…',
  cveTooltip: 'Search by Common Vulnerabilities and Exposures Identifier',
  cwePlaceholder: 'Search by CWE…',
  cweTooltip: 'Search by Common Weakness Enumeration Identifier',
  ghsaPlaceholder: 'Search by GHSA…',
  ghsaTooltip: 'Search by GitHub Security Advisory Identifier',
  languagePlaceholder: 'Search by Languages...(c,c++)',
  languageTooltip: 'Search for projects that use a particular language',
};
