// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { User } from '@auth0/auth0-spa-js';
import { headerConstants } from '@config';
import { environment } from '@environment';
import * as FullStory from '@fullstory/browser';
import { first } from 'rxjs/operators';

@Component({
  selector: 'lfx-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentUrl = window.location.href;

  constructor(private authService: AuthService) {
    this.setLFxHeaderScript();
    this.setLFxFooterScript();
  }

  ngOnInit(): void {
    FullStory.init({ orgId: 'MDAHR', devMode: !environment.production });

    const lfxHeader: any = document.getElementById('lfx-header');

    if (lfxHeader) {
      lfxHeader.docslink = environment.lfxHeader.docsLink;
      lfxHeader.supportlink = environment.lfxHeader.supportLink;
      lfxHeader.faqlink = environment.lfxHeader.faqLink;
      lfxHeader.logouturl = window.location.origin;
      lfxHeader.callbackurl = window.location.origin;
      lfxHeader.userefreshtoken = true;
      lfxHeader.links = [
        {
          title: headerConstants.secureMyProject,
          url: environment.lfxHeader.pccLink,
        },
      ];

      lfxHeader.beforeLogout = function () {
        window.localStorage.removeItem('accessIdentity');
      };

      this.authService.user$.subscribe((data: User | undefined | null) => {
        if (data) {
          lfxHeader.authuser = data;

          FullStory.identify(data.nickname as string, {
            displayName: data.name,
            email: data.email,
          });
        }
      });
    }

    this.handleBadHandleLogin();

    // Add event listener to support tooltip on mobile view.
    document.body.addEventListener('touchstart', function () {
      document.body.classList.add('touched');
    });
  }

  async handleBadHandleLogin() {
    if (this.currentUrl.includes('code=')) {
      this.authService.isAuthenticated$.pipe(first()).subscribe(isLoggedIn => {
        if (!isLoggedIn) {
          this.authService.loginWithRedirect();
        }
      });
    }
  }

  private setLFxHeaderScript() {
    const headerScript = document.createElement('script');

    headerScript.setAttribute('src', environment.lfxHeaderCDN);
    headerScript.setAttribute('async', 'true');
    document.head.appendChild(headerScript);
  }

  private setLFxFooterScript() {
    const footerScript = document.createElement('script');

    footerScript.setAttribute('src', environment.lfxFooterCDN);
    footerScript.setAttribute('async', 'true');
    document.head.appendChild(footerScript);
  }
}
