// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PaginationInput } from '@models';
import { tablePaginatorConstants } from '@config';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'lfx-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent implements OnInit {
  @Input() pagination!: PaginationInput;
  @Input() currentPage: number = 1;
  @Input() totalRecords: number = 0;
  @Output() prevNextBtnEmitter = new EventEmitter();
  @Output() pageChangeEmitter = new EventEmitter();
  @ViewChild('paginatorInput') paginatorInput!: ElementRef;
  totalNoOfPages: number = 0;
  prevPage: number = 1;
  apiTimeout!: NodeJS.Timeout;
  tablePaginatorConstants = tablePaginatorConstants;
  caretLeftIcon = faCaretLeft;
  caretRightIcon = faCaretRight;
  constructor() {}

  public ngOnInit(): void {}

  public prevBtnClick(): void {
    if (this.currentPage && Number(this.currentPage) > 1) {
      this.prevNextBtnEmitter.emit({
        btn: 'prev',
      });
    }
  }

  public nextBtnClick(): void {
    if (this.currentPage && Number(this.currentPage) < this.totalNoOfPages) {
      this.prevNextBtnEmitter.emit({
        btn: 'next',
      });
    }
  }

  public jumpToPage(): void {
    if (this.apiTimeout) {
      clearTimeout(this.apiTimeout);
    }

    this.apiTimeout = setTimeout(() => {
      const providedInput =
        this.paginatorInput.nativeElement &&
        this.paginatorInput.nativeElement.value;

      if (providedInput) {
        const positiveNumRegex = new RegExp('^[1-9]d*');

        if (
          positiveNumRegex.test(providedInput) &&
          Number(providedInput) <= this.totalNoOfPages
        ) {
          this.currentPage = providedInput;
          this.prevPage = this.currentPage;
          this.pageChangeEmitter.emit(this.currentPage);
        } else {
          // add toaster here
          this.currentPage = this.prevPage;
        }
      }
    }, 1000);
  }

  public currentPageDetails(): string {
    const firstRecordOfCurrentPage =
      this.pagination.limit * Number(this.pagination.page) -
      (this.pagination.limit - 1);
    let lastRecordOfCurrentPage =
      this.pagination.limit * Number(this.pagination.page);

    if (this.currentPageNumber() === Number(this.pagination.page)) {
      // if user enters last page number
      lastRecordOfCurrentPage = this.totalRecords || 0;
    }

    return `${tablePaginatorConstants.showingResults} ${
      firstRecordOfCurrentPage || 0
    } - ${lastRecordOfCurrentPage || 0} ${tablePaginatorConstants.of} ${
      this.totalRecords || 1
    }`;
  }

  public currentPageNumber() {
    this.totalNoOfPages = Math.ceil(this.totalRecords / this.pagination.limit);

    if (this.totalRecords > 0) {
      return this.totalNoOfPages;
    } else {
      return 1;
    }
  }
}
