// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit } from '@angular/core';
import { poweredByConstants } from '@lfx/config';

@Component({
  selector: 'lfx-powered-by-logo',
  templateUrl: './powered-by-logo.component.html',
  styleUrls: ['./powered-by-logo.component.scss'],
})
export class PoweredByLogoComponent implements OnInit {
  @Input() logoType: string = 'snyk';
  @Input() version: string = 'v1';
  poweredByConstant = poweredByConstants;

  constructor() {}

  ngOnInit(): void {}
}
