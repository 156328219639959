// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faChevronDown,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { DropDown } from '@models';

@Component({
  selector: 'lfx-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @Input() dropdownLabel: string = '';
  @Input() selectedItem: string = '';
  @Input() data: DropDown[] = [];
  @Input() errorMessage: string = '';
  @Input() disabled: boolean = false;
  @Output() selectItemEmitter = new EventEmitter();
  @Output() clearItemEmitter = new EventEmitter();

  hasToggle: boolean = false;
  downIcon = faChevronDown;
  closeIcon = faTimesCircle;

  constructor() {}

  ngOnInit(): void {}

  public toggleDropdown(): void {
    if (!this.disabled) {
      this.hasToggle = !this.hasToggle;
    }
  }

  public onSelect(item: DropDown): void {
    if (this.selectedItem !== item.label && !this.disabled) {
      this.hasToggle = false;
      this.selectedItem = item.formattedLabel || '';
      this.selectItemEmitter.emit(item);
    }
  }

  public closeDropdown(): void {
    this.hasToggle = false;
  }

  public clearSearch(): void {
    this.selectedItem = '';
    this.clearItemEmitter.emit();
  }
}
