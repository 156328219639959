// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'lfx-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() modalIdentifier: string = '';
  @Input() template!: TemplateRef<any>;
  @Input() customNgxModalClass: string = '';
  @Input() closable: boolean = true;
  constructor() {}

  ngOnInit(): void {}
}
