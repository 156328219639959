// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, args?: any): string | number {
    return value
      ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : 0;
  }
}
