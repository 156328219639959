// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByStringField',
})
export class SortByStringFieldPipe implements PipeTransform {
  transform(
    array: any[] = [],
    key: any,
    sortType = '',
    sortByLowerCase = false
  ): any[] {
    if (!array || !array.length) {
      return array;
    }

    if (sortType === 'desc') {
      return array.sort((a, b) => {
        // if value is in Proper case
        // then make it lowercase
        if (sortByLowerCase) {
          a[key] = a[key].toLowerCase();
          b[key] = b[key].toLowerCase();
        }

        return a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0;
      });
    } else {
      return array.sort((a, b) => {
        // if value is in Proper case
        // then make it lowercase
        if (sortByLowerCase) {
          a[key] = a[key].toLowerCase();
          b[key] = b[key].toLowerCase();
        }

        return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
      });
    }
  }
}
