// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'seperateNumberByComma',
})
export class SeperateNumberByCommaPipe implements PipeTransform {
  transform(value: any, ...args: unknown[]): string {
    return value
      ? value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      : value;
  }
}
