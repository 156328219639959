<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<div
  #barChartContainer
  *ngIf="data"
  class="flex-col block w-full"
  [ngStyle]="{ 'height.px': height }"
></div>

<div class="flex justify-center items-center mt-6" *ngIf="showCustomlegend">
  <div
    [title]="legend?.name"
    class="chart-legend"
    [ngStyle]="{ backgroundColor: legend.color }"
    *ngFor="let legend of data"
  >
    {{ legend.name }}
  </div>
</div>

<div class="flex justify-center align-center items-center h-full" *ngIf="!data">
  <div class="text-gray-900">No issues found.</div>
</div>
