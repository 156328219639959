// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { PageNotFoundComponent } from './shared/components';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: ':foundationId/foundation-details',
    loadChildren: () =>
      import('./modules/foundation-details/foundation-details.module').then(
        m => m.FoundationDetailsModule
      ),
  },
  {
    path: ':sfid/acs-connect-account',
    loadChildren: () =>
      import(
        './modules/security-authorization/security-authorization.module'
      ).then(m => m.SecurityAuthorizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: ':sfid',
    loadChildren: () =>
      import('./modules/security-tabs/security-tabs.module').then(
        m => m.SecurityTabsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
