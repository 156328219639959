// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const vulnerabilitiesDetectedConstants = {
  vulnerabilitiesDetected: 'Vulnerabilities Detected',
  all: 'all',
  fixed: 'fixed',
  open: 'open',
  openFixableKey: 'openFixable',
  openFixable: 'openfixable',
  openVulnerabilities: 'open_vulnerabilities',
  totalVulnerabilities: 'total_vulnerabilities',
  fixedVulnerabilities: 'fixed_vulnerabilities',
  fixableVulnerabilities: 'fixable_vulnerabilities',
  openVulnerabilitiesText: 'open vulnerabilities',
  totalVulnerabilitiesText: 'total vulnerabilities',
  fixedVulnerabilitiesText: 'fixed vulnerabilities',
  fixableVulnerabilitiesText: 'fixable vulnerabilities',
  asOfToday: 'As of today',
  critical: 'critical severity',
  high: 'high severity',
  medium: 'medium severity',
  low: 'low severity',
  year: 'year',
  quater: 'quarter',
  month: 'month',
  week: 'week',
  day: 'day',
  errorMessage: 'No data available',
};
