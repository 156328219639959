// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export enum CodeSecretsEnum {
  developersFilter = 'developersFilter',
  statesFilter = 'statesFilter',
  alertTypesFilter = 'alertTypesFilter',
  repositoriesFilter = 'repositoriesFilter',
  nonInclusiveWordFilter = 'nonInclusiveWordFilter',
  codeSecretEventTypesFilter = 'codeSecretEventTypesFilter',
}
