// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const nonInclusiveLanguageConstants = {
  nonInclusiveLanguage: 'non-inclusive language',
  noNonInclusiveAlertsAvailable: 'No alerts available',
  detectedOn: 'Detected On',
  description: 'Description',
  repository: 'Repository',
  developer: 'Developer',
  actionTaken: 'Action Taken',
  takeAction: 'Take Action',
  active: 'Active',
  notImportant: 'Not Important',
  dismissed: 'Dismissed',
  notify: 'Notify',
  remind: 'Remind',
  resolve: 'Resolve',
  resolved: 'Resolved',
  ignore: 'Ignore',
  falsePositive: 'False Positive',
  triage: 'Triage',
  triaged: 'Triaged',
  availableActions: 'available actions',
  ignored: 'Ignored',
  notifyAll: 'Notify All',
  resendAll: 'Resend All',
  notified: 'Notified',
  notifyDeveloper: 'Notify Developer',
  sendReminder: 'Send Reminder',
  noAlertFound: 'No alert found',
  sendNotificationSuccessMessage:
    'The email notification has been successfully sent to the developer(s).',
  sendNotificationErrorMessage:
    ' Failed to send notification. Please file a support ticket through help icon.',
  close: 'Close',
  loadingMessage: 'Please wait... we are sending the email notification.',
  confirmationMessage: 'Please confirm that you wish to send a notification.',
  cancel: 'Cancel',
  sendNotification: 'Send Notification',
  areYouSure: 'Are you sure?',
  privateEmailMsg1: 'Email can not be sent to',
  privateEmailMsg2: 'as email ID is marked as private.',
  getHelp: 'Get Help',
  alertType: 'Alert Type',
  Location: 'Location',
};
