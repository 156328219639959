<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<ngx-smart-modal
  [identifier]="modalIdentifier"
  [customClass]="customNgxModalClass"
  [closable]="closable"
>
  <ng-template *ngTemplateOutlet="template"></ng-template>
</ngx-smart-modal>
