<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->

<lfx-header-v2 id="lfx-header" product="LFX Security"></lfx-header-v2>
<div id="main-content">
  <div id="app">
    <div id="router-container">
      <router-outlet></router-outlet>
    </div>
    <lfx-footer></lfx-footer>
  </div>
</div>
