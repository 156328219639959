// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const bluBracketFiltersConstants = {
  addFilters: 'add filters',
  clearAllFilters: 'clear all filters',
  resetFilters: 'reset filters',
  applyFilters: 'apply filters',
  reset: 'reset',
  apply: 'apply',
};
