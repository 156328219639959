<!-- Copyright The Linux Foundation and each contributor to LFX. -->
<!-- SPDX-License-Identifier: MIT -->
<div class="flex flex-col-reverse sm:flex-row justify-center items-center">
  <div
    class="flex flex-wrap sm:flex-col justify-around legend-container"
    *ngIf="showLegend && doughnutChartData && doughnutChartData.length"
  >
    <div
      [title]="legend.name"
      class="chart-legend"
      [ngStyle]="{ backgroundColor: legend.color }"
      *ngFor="let legend of doughnutChartData"
    >
      {{ legend.name }}
    </div>
  </div>
  <div class="md:flex-1">
    <div
      *ngIf="doughnutChartData; else errorContainer"
      #doughnutChartContainer
      class="flex-col"
      [ngStyle]="{ 'width.px': width, 'height.px': height }"
    ></div>
  </div>
</div>

<ng-template #errorContainer>
  <div class="text-xs-11 text-center">
    {{ doughnutChartConstants.errorMessage }}
  </div>
</ng-template>
