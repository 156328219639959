// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export const vulnerabilitiesRepositoriesTableViewConstants = {
  repository: 'Repository',
  PastVulnerabilitiesFixed: 'Past Vulnerabilities Fixed',
  openVulnerabilities: 'Open Vulnerabilities',
  critical: 'Critical',
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  C: 'C',
  H: 'H',
  M: 'M',
  L: 'L',
  viewDetails: 'View Details',
  minimize: 'Minimize',
  fixable: 'Fixable',
  NoVulnerabilitiesDetected: 'No vulnerabilities detected',
  back: 'Back',
  issue: 'Issue',
  severity: 'Severity',
  cve: 'CVE',
  cwe: 'CWE',
  ghsa: 'GHSA',
  state: 'State',
};
